import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/SEO'
import WorkflowLab from '../../components/workflow-lab'
import Nav from '../../components/Nav'
import Styles from '../../styles/doc.module.scss'
import ContentStyle from '../../styles/content.module.scss'
import Footer from '../../components/Footer'

const LabPage = ({ location }) => {
  return (
    <>
      <SEO
        title="Workflow Lab"
        description={`The Losant Workflow Lab is an interactive training tool that provides a set of challenges to solve using Losant's Visual Workflow Engine.`}
        keywords={['Losant Workflow Lab', 'Losant Workflow Practice']}
      />
      <Nav location={location} />
      <div style={{ padding: '40px' }} className={Styles.DocWrapper}>
        <div className={ContentStyle.SectionWrapper}>
          <div
            className={ContentStyle.Section}
            style={{ maxWidth: '900px' }}
            role="main"
          >
            <h1>Losant Workflow Lab</h1>
            <p>
              The Losant Workflow Lab is an interactive training tool that
              provides a set of challenges to solve using{' '}
              <a target="_blank" href="/workflows/overview/">
                Losant's Visual Workflow Engine
              </a>
              . The Workflow Lab is intended to be used by both new and
              experienced Losant Developers to grow their workflow development
              skills.
            </p>
            <p>
              If you're new to the Workflow Lab, please review the{' '}
              <a target="_blank" href="/workflows/workflow-lab/overview/">
                Workflow Lab Overview
              </a>{' '}
              before continuing. If you need help, please see the{' '}
              <a href="#workflow-lab-troubleshooting">Troubleshooting</a>{' '}
              section below.
            </p>

            <WorkflowLab />
            <h2 id="workflow-lab-troubleshooting" style={{ marginTop: 35 }}>
              Troubleshooting
            </h2>
            <p>
              If you have trouble completing a test in the Workflow Lab, a
              Solution Workflow for each test suite is available on{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/Losant/losant-workflow-lab/tree/master/solution-workflows/"
              >
                GitHub
              </a>
              . Each Solution Workflow is Losant's recommended way of solving
              these problems.
            </p>
            <p>
              If the Solution Workflows do not address your concern, feel free
              to post a question in the Workflow Lab section of the{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://forums.losant.com/c/workflow-lab"
              >
                Losant Forums
              </a>
              .
            </p>
            <h3>Common Problems:</h3>
            <ul>
              <li>
                If your "Actual Output" for an test looks unfamiliar, be sure to
                check that you are triggering the correct Webhook/Workflow. It's
                possible to have two workflows listening to the same Webhook.
              </li>
              <li>
                The Workflow Lab expects your workflow to return valid JSON.
                It's important to ensure that within the Webhook Reply Node, you
                add a header of "Content-Type" and it's set to
                "application/json"
              </li>
            </ul>

            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default LabPage

export const pageQuery = graphql`
  query {
    allNavigationJson {
      edges {
        node {
          id
          name
          order
          links {
            name
            url
            links {
              name
              url
            }
          }
        }
      }
    }
  }
`
