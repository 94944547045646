import React from 'react'

const Footer = ({ link }) => {
  const linkToForums = link || 'https://forums.losant.com'
  return (
    <div style={{ marginTop: '80px' }}>
      <hr />
      <div
        style={{
          fontStyle: 'italic',
          fontSize: '14px',
          display: 'flex',
        }}
      >
        <div style={{ textAlign: 'right', margin: '0 auto' }}>
          <img
            style={{
              width: '30px',
              marginRight: '5px',
              border: 'none',
            }}
            src="/images/losant-red-logo.svg"
            alt="red losant logo"
          />{' '}
          <a
            style={{ color: '#505050' }}
            href={linkToForums}
            rel="noopener noreferrer"
            target="_blank"
          >
            Need Help? Your feedback and questions are very important to us. Let
            us know in the Losant Forums.
          </a>
        </div>
      </div>
      <p
        style={{
          fontStyle: 'italic',
          fontSize: '14px',
          color: '#505050',
          textAlign: 'center',
        }}
      >
        © {new Date().getFullYear()} Losant IoT, Inc
      </p>
    </div>
  )
}

export default Footer
